/** @module ComponentsApp */
import React, { useEffect } from "react";
import { useLocation } from "react-router";

// Import Components
// import Itma from "./vistas/Itma";
import AboutUs from "../Nosotros";
import Contacto from "../Contacto/contacto";

import Carousel from "./vistas/Carousel";
import Elementos from "./vistas/Elementos";
import Video from "./vistas/Video";

/**
 * Home
 *
 * Renderizara las 3 partes importantes del Home, parte superior, la
 * introduccion y las noticias
 */

const Home = props => {

  const { pathname } = useLocation()
  useEffect(() => {
    setTimeout(() => {
      switch (pathname) {
        case "/nosotros":
          window.scrollTo(0, document.querySelector(`.nosotros__container`).offsetTop);
          break;
        case "/contacto":
          window.scrollTo(0, document.querySelector(`.contacto__container`).offsetTop);
          break;
        default: break;
      }
    }, 5)
  }, [pathname])

  return (
    <main className="inicioContainer">

      <Video />

      <Carousel />

      <Elementos />

      <AboutUs />

      {/* <Itma /> */}

      {/* <Productos /> */}

      <Contacto />

    </main>
  );
}

export default Home;
