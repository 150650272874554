/** @module ComponentsApp */
import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

/**
 * Contacto Componente
 */

const Contacto = props => {
  return (
    <div className="contacto__container">

      <div className="contacto__content">
        {/* <Img src={[ "/imagenes/contactos/worldmap.webp", "/imagenes/contactos/worldmap.png" ]} className="worldMap" /> */}
        <div className="worldMap">
          <img src="/imagenes/contactos/worldmap.webp" alt="world map" />
        </div>
        
        <section className="contact_info fit">
          <h3>{I18n.t("Contacto")}</h3>
          <section>
            <i className="fas fa-building fa-2x" />
            <p>Tacome, S.A.</p>
          </section>
          <section>
            <i className="fas fa-home fa-2x" />
            <p>
              Avda. Textil, 19 | 46870 <br /> Ontinyent (Valencia) - España
            </p>
          </section>
          <section>
            <i className="fas fa-phone fa-2x" />
            <p>(+34) 962 383 661</p>
          </section>
          <section>
            <i className="fas fa-fax fa-2x" />
            <p>(+34) 962 383 561</p>
          </section>
          <section>
            <p className="link">
              {/* <a>tacome@tacome.com</a> */}
              <a href="mailto:vicente@tacome.com">vicente@tacome.com</a>
            </p>
          </section>
          <section>
            <p className="link">
              <a href="www.tacome.com">www.tacome.com</a>
            </p>
          </section>
        </section>
      </div>

    </div>
  );
}

const mapStateToProps = state => ({
  i18n: state.i18n
})

export default connect(mapStateToProps)(Contacto);
