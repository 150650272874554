import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { I18n } from "react-redux-i18n";

/**
 * HomeIntroduccion
 */

const Elemento = connect(store => ({ i18n: store.i18n }))(props => {
  let backgroundImage = ""
  if(props.images) backgroundImage = props.images.map(image => `url("${image}")`).join(",")

  return (
    <div className={`element__container`} style={{ backgroundImage }} >
        <article className="grid gridCenter pad-25">
          <p className="textC"><i className="fas fa-tools fa-2x white " style={{ height: "29px", width: "28px" }} /></p>
          <h4 className="bold textC letSpa-1">{props.titulo}</h4>
          <p className="lineTop textC ">{props.texto}</p>
        </article>
      </div>
  )
})
Elemento.propTypes = {
  images: PropTypes.array,
  titulo: PropTypes.string,
  color: PropTypes.string,
  texto: PropTypes.string,
}

const Elementos = props => {

  return (
    <section className="introduccionElementos">
    
        <Elemento 
            images={[ "/imagenes/homepage/boxes/caja_1.webp", "/imagenes/homepage/boxes/caja_1.jpg" ]}
            // titulo={"CONSTRUCCIÓN DE MAQUINARIA TEXTIL"}
            titulo={I18n.t("elemento1.titulo")}
            // texto={"Especialistas en el desarrollo y construcción de maquinaria e instalaciones para la industria textil."}
            texto={I18n.t("elemento1.texto")}
        />
        <Elemento
            images={[ "/imagenes/homepage/boxes/caja_2.webp", "/imagenes/homepage/boxes/caja_2.jpg" ]}
            // titulo={"ASISTENCIA TÉCNICA Y MANTENIMIENTO"}
            titulo={I18n.t("elemento2.titulo")}
            // texto={"Servicios de mantenimiento industrial.",}
            texto={I18n.t("elemento2.texto")}
        />
        <Elemento
            images={[ "/imagenes/homepage/boxes/caja_3.webp", "/imagenes/homepage/boxes/caja_3.jpg" ]}
            // titulo={"TACOME ELECTRICS"}
            titulo={I18n.t("elemento3.titulo")}
            // texto={"Desarrollo y fabricación de equipos de control para maquinaria.Automatización industrial.",}
            texto={I18n.t("elemento3.texto")}
        />
        <Elemento
            images={[ "/imagenes/homepage/boxes/caja_4.webp", "/imagenes/homepage/boxes/caja_4.jpg" ]}
            // titulo={"INDUSTRIA 4.0"}
            titulo={I18n.t("elemento4.titulo")}
            // texto={"Gestión energética, control de producción, consultaría digital y desarrollo de software a medida."}
            texto={I18n.t("elemento4.texto")}
        />

    </section>
  );
}
Elementos.propTypes = {
}

// State / Dispatch to Props
const mapStateToProps = state => ({ 
  ...state.productos,
  i18n: state.i18n
});
const mapDispatchToProps = dispatch => ({
  selectProductByKey: key => dispatch({ type: "products/select_product_by_key", key })
});

export default connect(mapStateToProps, mapDispatchToProps)(Elementos);
