
const initialState = {
  fixed: false
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case "heade/set_fixed": {
      let fixed = action.fixed;

      return {
        ...state,
        fixed
      }
    }
    default:
      return state;
  }
}
