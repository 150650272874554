import React from "react"
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { useNavigate } from "react-router"

const ContactUs = props => {
    const navigate = useNavigate();

    const handleClick = ev => {
        navigate("/contacto")
    }

    return (
        <div className="contactUs__container">
            <p onClick={handleClick}>
                {I18n.t("Si necesitas mas información")}
                <br />
                <b>{I18n.t("CONTACTANOS")}</b>
            </p>
        </div>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(ContactUs)