//Dependencies
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";

//Components
import Home from "./components/Inicio/inicio";
import EstampacionDigital from "./components/Productos/EstampaciónDigital/EstampacionDigital"
import Vaporizador from "./components/Productos/Vaporizador/Vaporizador"
import Rame from "./components/Productos/Rame/Rame"
import RolladoraEmpaquetadora from "./components/Productos/RolladoraEmpaquetadora/RolladoraEmpaquetadora";
import Secadero from "./components/Productos/Secaderos/Secadero"
import Empaquetadora from "./components/Productos/Empaquetadora/Empaquetadora"
import Percha from "./components/Productos/Percha/Percha"
import Tundosa from "./components/Productos/Tundosa/Tundosa"
import Preparaciones from "./components/Productos/Preparaciones/Preparaciones"
import TrenDeLavadoMain from "./components/Productos/TrenDeLavado/TrenDeLavado";
import Productos from "./components/Productos/Productos";

const AppRoutes = props => {

  const { pathname } = useLocation()
  useEffect(() => { window.scrollTo(0, 0); }, [pathname])

  return (
    <main id="main" className={`${props.headerFixed ? "fixed " : ""}`}>
      <Routes>
        <Route path="/Empaquetadoras"  element={<Empaquetadora />} />
        <Route path="/Empaquetadora"  element={<Empaquetadora />} />
        <Route path="/EstampacionDigital"  element={<EstampacionDigital />} />
        <Route path="/Perchas"  element={<Percha />} />
        <Route path="/Percha"  element={<Percha />} />
        <Route path="/Preparaciones"  element={<Preparaciones />} />
        <Route path="/Preparacion"  element={<Preparaciones />} />
        <Route path="/Rame"  element={<Rame />} />
        <Route path="/RolladoraEmpaquetadora"  element={<RolladoraEmpaquetadora />} />
        <Route path="/Secaderos"  element={<Secadero />} />
        <Route path="/Secadero"  element={<Secadero />} />
        <Route path="/TrenDeLavado"  element={<TrenDeLavadoMain />} />
        <Route path="/Tundosas"  element={<Tundosa />} />
        <Route path="/Tundosa"  element={<Tundosa />} />
        <Route path="/Vaporizador"  element={<Vaporizador />} />
        <Route path="/Productos"  element={<Productos />} />
        <Route index path="*" element={<Home />} />
      </Routes>
    </main>

  );
}
AppRoutes.propTypes = {
  headerFixed: PropTypes.bool
}

const mapStateToProps = state => ({
  headerFixed: state.header.fixed
})

export default connect(mapStateToProps)(AppRoutes);
