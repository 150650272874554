import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";

import Utils from "../utils"

/**
 * Footer
 */
const countryOptions = [
  { key: "es", value: "es", flag: "es", text: "Español" },
  { key: "en", value: "en", flag: "gb", text: "English" }
];

const Footer = props => {
  const { locale } = props.i18n;
  const selectLangRef = useRef()
  const optionsLangRef = useRef()

  let visible = "visible"

  let eventClick = useCallback((ev) => {
    if(selectLangRef.current.contains(ev.target) === false)
      if(selectLangRef.current.classList.contains(visible))
        selectLangRef.current.classList.remove(visible)
  }, [ visible ])

  useEffect(() => {
    window.addEventListener("click", eventClick)
    return () => {
      window.removeEventListener("click", eventClick)
    }
  }, [eventClick])

  const handleOnClickOption = value => {
    props.selectLang(value)
    Utils.setCookie("language", value)
    selectLangRef.current.classList.toggle(visible)
  }
  const handleOnClickLang = ev => {
    selectLangRef.current.classList.toggle(visible)
  }

  let flagTag = countryOptions.find(o => o.value === locale).flag;

  return (
    <footer>

      <div className="generalitat-ayuda">
        <div className="image">
          <img src="/imagenes/footer/generalitat-ayuda.png" alt="ayuda de la generalidad logo"/>
        </div>
        <div className="text">
          <span>
            Expediente ECOTDI/2021/9/46 de la Subdirección General de Empleo destinada a fomentar la contratación 
            temporal de personas con diversidad funcional severa o enfermedad mental en el ámbito territorial de la Comunitat Valenciana, concediendo 7.772,16.-€
          </span>
        </div>
      </div>

      <div className="language__container">
        <p className="nomargin pad-5"> © Tacome 2019</p>
        <div className="select__object" ref={selectLangRef} >
          <span onClick={handleOnClickLang}>
            <i className="fas fa-globe" />
            <img src={`https://flagcdn.com/16x12/${flagTag}.png`} alt={`${flagTag} logo`} />
          </span>
          <div className="options__container" ref={optionsLangRef}>
            {countryOptions.map((params, index) =>
              <span className="option__container" key={index} onClick={ev => handleOnClickOption(params.value)}>
                <img src={`https://flagcdn.com/16x12/${params.flag}.png`} alt={`${params.flag} logo`}/>
                {params.text}
              </span>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}
Footer.propTypes = {
  i18n: PropTypes.object,

  selectLang: PropTypes.func
};

const mapStateToProps = state => ({
  i18n: state.i18n
});
const mapDispatchToProps = dispatch => ({
  selectLang: value => dispatch(setLocale(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
