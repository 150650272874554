import React from "react";

export default class ButtonGrid extends React.Component {
  render() {
    const { cambiarEstado, hideShow } = this.props;
    return (
      <section
        className={`buttonNav ${hideShow}`}
        onClick={() => cambiarEstado()}
      >
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </section>
    );
  }
}
