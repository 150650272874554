import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux"
import { Carousel } from "react-responsive-carousel";
import { Img } from "react-image";
import { Link } from "react-router-dom";

const CarouselMain = props => {
  return (
    <Carousel
      interval={6000}
      transitionTime={600}
      infiniteLoop
      autoPlay
      stopOnHover={false}
      showThumbs={false}
      showArrows={true}
      showStatus={false}
      showIndicators={false}
      className="carousel_tacome"
    >
      <section className="element">
        <Img src={[ "/imagenes/homepage/carousel/000 Rame.png" ]} />
        <div className="contentText">
            <Link to="Rame">Rame</Link>
        </div>
      </section>
      <section className="element" >
        <Img src={["/imagenes/homepage/carousel/003 Vaporizador.png" ]} />
        <div className="contentText">
            <Link to="Vaporizador">{I18n.t("Vaporizador")}</Link>
        </div>
      </section>

      <section className="element" >
        <Img src={[ "/imagenes/homepage/carousel/002 digital.webp" ]} />
        <div className="contentText">
            <Link to="EstampacionDigital">Digital</Link>
        </div>
      </section>
    </Carousel>
  );
}

const mapStateToProps = state => ({
  i18n: state.i18n
})

export default connect(mapStateToProps)(CarouselMain);
