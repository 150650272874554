import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const RolladoraEmpaquetadoraMain = props => {
    
    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return

        mesh.scale.set(0.001, 0.001, 0.001);
        mesh.rotation.set(-Math.PI / 2,0,0)
        mesh.position.set(-2, 0, -1)
        scene.position.set(0, -1, 0)
        camera.position.set(3, 3, 3)
    }

    return (
        <main className="producto__main rolladoraempaquetadora__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.RolladoraEmpaquetadora.Rolladora Empaquetadora")}</h1>
                </div>
                <TresDe 
                    // url="/imagenes/productos/Rolladora Empaquetadora/Rolladora Empaquetadora.obj" 
                    // materialsUrl="/imagenes/productos/Rolladora Empaquetadora/Rolladora Empaquetadora.mtl" 
                    url="/imagenes/productos/Rolladora Empaquetadora/Rolladora Empaquetadora.stl" 
                    modifyGeometry={modifyGeometry}
                />
            </div>
            
            <ContactUs />

            <div className="modelo__container">
                <Img height="auto" width="80%" src={[ "/imagenes/productos/Rolladora Empaquetadora/esquema.jpg" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.RolladoraEmpaquetadora.Rolladora Empaquetadora")}</h1>
                <p>
                    {I18n.t("_Productos.RolladoraEmpaquetadora.Máquina de repasado y empaquetado de piezas de tejido La máquina permite el repasado, rollado y empaquetado de rollos de distinto diámetro y hasta un ancho de pieza de 1600 mm, provenientes de carros bota (“burras”)")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img src={[ "" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.RolladoraEmpaquetadora.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Ancho de tabla")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.1800 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Ancho útil de trabajo")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Ancho de tejido")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.1600 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Ancho de tabla")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.200 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Ancho de Soldadura")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.200 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Tensión de Conexión")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.400 VAC+N")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Frecuencia")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.50 Hz")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Potencia Instalada")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.26 Kw")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.Tensión maniobra")}</td>
                            <td>{I18n.t("_Productos.RolladoraEmpaquetadora.24 VDC")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(RolladoraEmpaquetadoraMain)
