import React from "react"
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";

const EmpaquetadoraMain = props => {

    const modifyGeometry = (geometry, mesh, camera) => {
        if(!geometry) return

        geometry.rotation.set(-(Math.PI / 2), 0, -(Math.PI / 4))
        // geometry.position.set(0, 0, 2)
        geometry.scale.set(0.0005, 0.0005, 0.0005)
        geometry.position.set(0, 0, 2)

        // camera.position.set(4.23, 1.61 ,2.57)
        camera.position.set(3, 2, 3)
    }

    return (
        <main className="producto__main empaquetadora__main">
            <div className="informacion__container">
                <div className="text" >
                    <h1>{I18n.t("_Productos.Empaquetadora.empaquetadora")}</h1>
                </div>
                <TresDe 
                    // url="/imagenes/productos/Empaquetadoras/empaquetadora.stl"
                    url="/imagenes/productos/Empaquetadoras/empaquetadora.obj"
                    materialUrl="/imagenes/productos/Empaquetadoras/empaquetadora.mtl"
                    modifyGeometry={modifyGeometry}
                />
            </div>

            <ContactUs />

            <div className="modelo__container">
                <Img width="80%" height="auto" src={[ "/imagenes/productos/Empaquetadoras/esquema.jpg" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.Empaquetadora.empaquetadora")}</h1>
                <p>{I18n.t("_Productos.Empaquetadora.descripcion")}</p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Empaquetadoras/000 Empaquetadora.webp", "/imagenes/productos/Empaquetadoras/000 Empaquetadora.jpg" ]} />
                    </div>
                </Carousel>
            </div>
            {/* <div className="fichaTecnica__container">
                <h2>Ficha técnica</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>Ancho</td>
                            <td>1100</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.I18n
});

export default connect(mapStateToProps)(EmpaquetadoraMain)