import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";


import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const TrenDeLavadoMain = props => {

    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return

        mesh.scale.set(0.003, 0.003, 0.003)  
        mesh.position.set(.5, .15, 2.5)
        camera.position.set(3,1,1.5)
        scene.position.set(0, -1, 0)
    }

    return (
        <main className="producto__main trenDeLavado__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.Tren de Lavado.Tren de Lavado")}</h1>
                    <p>
                        {I18n.t("_Productos.Tren de Lavado.Producciónes de hasta")} <b>100 m/min</b>
                    </p>
                </div>
                <TresDe 
                    // url="/imagenes/productos/Tren de lavado/trenDeLavado.stl" 
                    url="/imagenes/productos/Tren de lavado/trenDeLavado low.stl" 
                    modifyGeometry={modifyGeometry}
                />
            </div>

            <ContactUs />

            <div className="modelo__container">
                <Img width="80%" height="auto" src={[ "/imagenes/productos/Tren de lavado/esquema.jpg" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.Tren de Lavado.Tren de Lavado")}</h1>
                <p >
                    {I18n.t("_Productos.Tren de Lavado.Línea automática sincronizada electrónicamente, con controles de temperatura y Rotámetros para el control de los productos químicos")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img src={[ "/" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.Tren de Lavado.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.Tren de Lavado.Ancho últi tejido")}</td>
                            <td>{I18n.t("_Productos.Tren de Lavado.3800 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tren de Lavado.Ancho total de mesa")}</td>
                            <td>{I18n.t("_Productos.Tren de Lavado.4000 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tren de Lavado.Velocidad global línea")}</td>
                            <td>{I18n.t("_Productos.Tren de Lavado.Hasta 100 m/min")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tren de Lavado.Peso de Tejidos")}</td>
                            <td>{I18n.t("_Productos.Tren de Lavado.Hasta 650 grs/m2")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tren de Lavado.Combustible ")}</td>
                            <td>{I18n.t("_Productos.Tren de Lavado.Gas / Vapor")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tren de Lavado.Potencia Instalada")}</td>
                            <td>{I18n.t("_Productos.Tren de Lavado.80 kW")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(TrenDeLavadoMain)