import React, {useCallback, useEffect, useRef, useState} from "react"
import { PropTypes } from "prop-types"

import { STLLoader } from "three/examples/jsm/loaders/STLLoader"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader"
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader"

import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { OrbitControls } from "@react-three/drei";

const commonPropTypes = {
    url: PropTypes.string,
    materialUrl: PropTypes.string,
    position: PropTypes.array,
    rotation: PropTypes.array,
    center: PropTypes.any,
    scale: PropTypes.number,
    onLoad: PropTypes.func,
    onLoadPercentage: PropTypes.func,
    modifyGeometry: PropTypes.func,
    useFrame: PropTypes.func
}

// Translate a geometry means
// X Y Z
// X is yellow means left right horizontal
// Y is green means height up down
// Z is blue means deep

const ModelStl = (props) => {
    const { camera, scene } = useThree()
    // url
    const { url } = props;
    // parametters
    const { position, rotation, center } = props;
    // functions
    const { onLoad, onLoadPercentage, modifyGeometry, useFrame: _useFrame } = props;
    // references
    const mesh = useRef();
    
    const geometry = useLoader(STLLoader, url, loader => {

    }, progress => {
        let progressAmount = (progress.loaded / progress.total) * 100
        onLoad(true); 
        onLoadPercentage(progressAmount);
        if(progressAmount === 100) {
            onLoad(false); 
            onLoadPercentage(0);
        }
    });

    useEffect(() => {
        if(center) geometry.center()
        if(modifyGeometry) modifyGeometry(geometry, mesh.current, camera, scene)
    }, [camera, center, geometry, modifyGeometry, scene])
    useFrame(() => {
        if(_useFrame) _useFrame(geometry, mesh.current, camera, scene)
    })

    let meshProps = {
        castShadow: true,
        receiveShadow: true,
        position: position !== undefined ? position : [ 0, 0, 0 ],
        rotation: rotation !== undefined ? rotation : [ 0, 0, 0 ]
    }

    return (
        <mesh { ...meshProps } ref={mesh}>
            <primitive attach="geometry" object={geometry} />
            <meshStandardMaterial attach="material" color="white" />
        </mesh>
    )
}
ModelStl.propTypes = {
    ...commonPropTypes
}
const ModelObj = (props) => {
    const { camera, scene } = useThree();
    // url
    const { url, materialUrl } = props;
    // parametters
    const { position, rotation, center } = props;
    // functions
    const { onLoad: _onLoad, onLoadPercentage, modifyGeometry, useFrame: _useFrame } = props;
    // references
    const mesh = useRef()
    
    let materialLoader = useLoader(MTLLoader, materialUrl)

    const onProgres = useCallback((progress) => {
        let progressAmount = (progress.loaded / progress.total) * 100
        _onLoad(true); 
        onLoadPercentage(progressAmount);
    }, [_onLoad, onLoadPercentage])
    const onLoad = useCallback(() => {
        _onLoad(false); 
        onLoadPercentage(0);
    }, [_onLoad, onLoadPercentage])
    const extensions = useCallback((loader) => {
        loader.manager.onLoad = onLoad
        if(materialLoader) loader.setMaterials(materialLoader)
    }, [materialLoader, onLoad])

    const geometry = useLoader(OBJLoader, url, extensions, onProgres)

    useEffect(() => {
        if(center) geometry.children.map(children => children.geometry.center ? children.geometry.center() : null)
        if(modifyGeometry) modifyGeometry(geometry, mesh.current, camera, scene)
    }, [camera, center, geometry, modifyGeometry, scene])

    useFrame(() => {
        if(_useFrame) _useFrame(geometry, mesh.current, camera, scene)
    })

    let meshProps = {
        // casShadow: true,
        // receiveShadow: true,
        // scale: scale !== undefined ? scale : 0.005,
        position: position !== undefined ? position : [ 0, 0, 0 ],
        rotation: rotation !== undefined ? rotation : [ 0, 0, 0 ]
    }

    return (
        <mesh { ...meshProps } ref={mesh}>
            <primitive object={geometry} />
        </mesh>
    )
}
ModelObj.propTypes = {
    ...commonPropTypes
}

const TresDe = ({ autoRotate: _autoRotate, cameraPosition: _cameraPosition, ...props }) => {
    const [ loading, setLoading ] = useState(false)
    const [ loadingPercentage, setLoadingPercentage ] = useState(0)

    let autoRotate = _autoRotate !== undefined ? _autoRotate : false;
    let cameraPosition = _cameraPosition !== undefined ? _cameraPosition : [ 2, 2, 2 ];

    let Model = ModelStl
    if(/(\.obj)/i.test(props.url)) Model = ModelObj;
    if(!props.url) return <div />
 
    return (
        <div className="image">
            {loading ? <span className="loading__container">Loading ... {parseInt(loadingPercentage)}%</span> : null}
            {/* <span className="loading__container">Loading ... {parseInt(loadingPercentage)}%</span> */}
            <Canvas camera={{ position: cameraPosition }} frameloop="demand"s>
                <gridHelper args={[10, 10, `white`, `gray`]} />
                {/* <Stats /> */}
                {/* <axesHelper /> */}
                <ambientLight castShadow intensity={0.1} />
                <directionalLight intensity={0.4} castShadow position={[ -10, 20, -10 ]} shadow-mapSize-height={512} shadow-mapSize-width={512} />
                <directionalLight intensity={0.4} castShadow position={[ 10, 20, 10 ]}  shadow-mapSize-height={512} shadow-mapSize-width={512} />
                <React.Suspense fallback={null}>
                    <Model 
                        {...props}
                        onLoad={state => setLoading(state)}
                        onLoadPercentage={state => setLoadingPercentage(state)}
                    />
                </React.Suspense>
                <OrbitControls
                    makeDefault
                    autoRotate={autoRotate}
                    autoRotateSpeed={1}
                    // enableZoom={false}
                />
            </Canvas>
        </div>
    )
}
TresDe.propTypes = {
    ...commonPropTypes,
    autoRotate: PropTypes.bool,
    cameraPosition: PropTypes.array,
}


export default TresDe