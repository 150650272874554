import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import Header from "./components/header";
import Footer from "./components/footer";
import AppRoutes from "./routes";

import store from "./Redux/store"

const App = props => {
  return (
    <Provider store={store}>
      <HashRouter>
        <aside className="App">
          <Header />
          <AppRoutes />
          <Footer />
        </aside>
      </HashRouter>
    </Provider>
  )
}

export default App;
