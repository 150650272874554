import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const SecaderoMain = props => {

    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return

        mesh.scale.set(0.003, 0.003, 0.003)  
        mesh.position.set(-.75, .3, 1.5)
        camera.position.set(2,1,3)
        scene.position.set(0, -1, 0)
    }

    return (
        <main className="producto__main secadero__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.Secadero.Secadero")}</h1>
                    <p>{I18n.t("_Productos.Secadero.Secados perfectos")}</p>
                    <p>
                        {I18n.t("_Productos.Secadero.Velocidades de hasta")} <b>25 m/min</b>
                    </p>
                </div>
                <TresDe 
                    // url="/imagenes/productos/Secaderos/secadero.stl" 
                    url="/imagenes/productos/Secaderos/secadero low.stl" 
                    // url="/imagenes/productos/Secaderos/secadero.obj"
                    modifyGeometry={modifyGeometry}
                />
            </div>

            <ContactUs />

            <div className="modelo__container">
                <Img height="100%" src={[ "" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.Secadero.Secaderos")}</h1>
                <p>
                    {I18n.t("_Productos.Secadero.Consigue los secados necesarios para cada uno de los tejidos de una manera sencilla y rápida")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Secaderos/000 Termofijadora.webp", "/imagenes/productos/Secaderos/000 Termofijadora.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Secaderos/001 Secadero digital.webp", "/imagenes/productos/Secaderos/001 Secadero digital.jpg" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.Secadero.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.Secadero.Ancho útil")}</td>
                            <td>{I18n.t("_Productos.Secadero.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Secadero.Velocidad de la máquina")}</td>
                            <td>{I18n.t("_Productos.Secadero.Hasta 25 m/min")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Secadero.Contenido de tejido")}</td>
                            <td>{I18n.t("_Productos.Secadero.150 - 200 m")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Secadero.Temperataura de trabajo")}</td>
                            <td>{I18n.t("_Productos.Secadero.100º - 180º")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Secadero.Calentamiento por")}</td>
                            <td>{I18n.t("_Productos.Secadero.Gas / Vapor / Aceite Térmico")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(SecaderoMain)