import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const PerchaMain = props => {
    return (
        <main className="producto__main percha__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.Percha.Percha")}</h1>
                    <p>{I18n.t("_Productos.Percha.Tratamiento en telas de alta velocidad")}</p>
                    <p>
                        {I18n.t("_Productos.Percha.Producciónes de hasta")} <b>22 m/min</b>
                    </p>
                </div>
                <TresDe />
            </div>
            
            <ContactUs />

            <div className="modelo__container">
                <Img height="100%" src={[ "" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.Percha.Percha")}</h1>
                <p>
                    {I18n.t("_Productos.Percha.La Perchadora Doble Acción, es una máquina clásica en ejecución pelo/contrapelo, indicada especialmente para toda clase de tejidos planos o de punto en los que se requiere un pelo largo, como: tejidos de lana de carda, tejidos de algodón, mantas convencionales de pelo, mantas Rachel, tejidos Ketten, Microfibra, etc")}
                </p>
                <p>
                    {I18n.t("_Productos.Percha.El accionamiento en la nueva generación de máquinas Perchadoras se efectúa electrónicamente, permitiendo una sincronización entre los diferentes ejes motrices")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Perchas/000 Percha.webp", "/imagenes/productos/Perchas/000 Percha.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Perchas/001 Percha.webp", "/imagenes/productos/Perchas/001 Percha.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Perchas/002 Percha.webp", "/imagenes/productos/Perchas/002 Percha.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Perchas/004 Control.webp", "/imagenes/productos/Perchas/004 Control.jpg" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.Percha.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.Percha.Ancho máximo del tejido")}</td>
                            <td>{I18n.t("_Productos.Percha.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Percha.Diámetro de la Bota")}</td>
                            <td>{I18n.t("_Productos.Percha.1130 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Percha.Velocidad de la Bota")}</td>
                            <td>{I18n.t("_Productos.Percha.100 rpm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Percha.Velocidad de producción")}</td>
                            <td>{I18n.t("_Productos.Percha.0 - 25 m/min")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Percha.Potencia instalada (sin aspirador)")}</td>
                            <td>{I18n.t("_Productos.Percha.41 kW")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(PerchaMain)