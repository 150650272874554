import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";


import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const EstampacionDigitalMain = props => {

    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return;

        geometry.scale.set(0.0004, 0.0004, 0.0004);
        geometry.position.set(0, 0, -1);
        camera.position.set(2.3, 0.27, 2.5)
        scene.position.set(0, -1, 0);
    }

    return (
        <main className="producto__main estampaciodigital__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.EstampacionDigital.Estampación Digital")}</h1>
                    <p>{I18n.t("_Productos.EstampacionDigital.Impresión de tejido de gran calidad.")}</p>
                    <p>
                        {I18n.t("_Productos.EstampacionDigital.Producción de hasta")} <b>1.200 m2/h</b>
                    </p>
                </div>
                <TresDe
                    // url="/imagenes/productos/Estampacion Digital/ESTAMPADORA DIGITAL low.stl"
                    url="/imagenes/productos/Estampacion Digital/ESTAMPADORA DIGITAL.obj"
                    materialUrl="/imagenes/productos/Estampacion Digital/ESTAMPADORA DIGITAL.mtl"
                    modifyGeometry={modifyGeometry}
                />
            </div>
            
            <ContactUs />

            <div className="modelo__container">
                <Img height="300px" width="100%" src={[ "/imagenes/productos/Estampacion Digital/esquema.jpg" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.EstampacionDigital.Estampación Digital")}</h1>
                <p >
                    {I18n.t("_Productos.EstampacionDigital.Impresiones de gran calidad con un sistema abierto que permite el uso de cualquier tinta, multiples opciónes de tejido, paletas personalizables y tratamientos de última generación")}
                </p>
                <p >
                    {I18n.t("_Productos.EstampacionDigital.Cuenta con la última tecnología en pantallas para su control y un sistema de conexión remota para su mantenimiento")}
                </p>
                <p >
                    {I18n.t("_Productos.EstampacionDigital.Trabajamos con nuestros cabezales “FUJI - DIMATIX” pueden ser reparados, lo cual reduce costes y tiempo de mantenimiento")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Estampacion Digital/000_digital.webp", "/imagenes/productos/Estampacion Digital/000_digital.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Estampacion Digital/001_digital.webp", "/imagenes/productos/Estampacion Digital/001_digital.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Estampacion Digital/002_digital.webp", "/imagenes/productos/Estampacion Digital/002_digital.jpg" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.EstampacionDigital.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.EstampacionDigital.Resolución horizontal")}</td>
                            <td>{I18n.t("_Productos.EstampacionDigital.400 - 1200 dpi")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.EstampacionDigital.Resolución vertical")}</td>
                            <td>{I18n.t("_Productos.EstampacionDigital.400 - 1200 dpi")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.EstampacionDigital.Ancho de estampación")}</td>
                            <td>{I18n.t("_Productos.EstampacionDigital.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.EstampacionDigital.Tinta")}</td>
                            <td>{I18n.t("_Productos.EstampacionDigital.Sistema abierto, permite utilizar cualquier tinta del mercado")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.EstampacionDigital.Tamaño de la gota")}</td>
                            <td>{I18n.t("_Productos.EstampacionDigital.6 - 30 pl")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.EstampacionDigital.Software")}</td>
                            <td>{I18n.t("_Productos.EstampacionDigital.Se puede trabajar con archivos TIFF y PSD, imagenes CMYK, multichanel y RGB")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(EstampacionDigitalMain)