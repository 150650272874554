import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const VaporizadorMain = props => {

    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return
        
        mesh.scale.set(0.003, 0.003, 0.003)  
        mesh.position.set(-.1, -.1, -.6)
        camera.position.set(2, 1, 2)
        scene.position.set(0, -1, 0)
    }

    return (
        <main className="producto__main vaporizador__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.Vaporizador.Vaporizador")}</h1>
                    <p>{I18n.t("_Productos.Vaporizador.Soluciones para un mejor rendimiento del tejido")}</p>
                    <p>
                        {I18n.t("_Productos.Vaporizador.Producciónes de hasta")} <b>40 m/min</b>
                    </p>
                </div>
                <TresDe
                    url="/imagenes/productos/Vaporizadores/vaporizador.stl" 
                    modifyGeometry={modifyGeometry}
                />
            </div>

            <ContactUs />

            <div className="modelo__container">
                <Img height="100%" src={[ `/imagenes/productos/Vaporizadores/esquema.jpg` ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.Vaporizador.Vaporizador")}</h1>
                <p >
                    {I18n.t("_Productos.Vaporizador.Mejora el rendimiento del tejido a la hora de tratarlo generando una estampación al grado perfecto de cada tejido")}
                </p>
                <p>
                    {I18n.t("_Productos.Vaporizador.Con un nuevo sistema de circulación de vapor conseguimos que el vapor fluya desde abajo hacia arriba, de este modo conseguimos que la temperatura del interior sea constante desde la entrada del tejido hasta su salida")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img src={[ `/imagenes/productos/Vaporizadores/000 Vaporizador.webp`, `/imagenes/productos/Vaporizadores/000 Vaporizador.jpg` ]} />
                    </div>
                    <div className="element">
                        <Img src={[ `/imagenes/productos/Vaporizadores/001 Vaporizador.webp`, `/imagenes/productos/Vaporizadores/001 Vaporizador.jpg` ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.Vaporizador.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.Vaporizador.Ancho de trabajo")}</td>
                            <td>{I18n.t("_Productos.Vaporizador.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Vaporizador.Ancho de tabla")}</td>
                            <td>{I18n.t("_Productos.Vaporizador.2000 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Vaporizador.Contenido")}</td>
                            <td>{I18n.t("_Productos.Vaporizador.2600 mm")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(VaporizadorMain)