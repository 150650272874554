import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";

/**
 * Productos Component
 */
const ProductosController = props => {
  return (
    <main className="productos__container">
      <div className="links__container">
        <span className="link">
          <img className="background" src="/imagenes/productos/Empaquetadoras/000 Empaquetadora.webp" alt="Empaquetadora"/>
          <Link to="/Empaquetadora">{I18n.t("Empaquetadora")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Estampacion Digital/000_digital.webp" alt="Estampadora digital"/>
          <Link to="/EstampacionDigital">{I18n.t("Estampación Digital")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Perchas/000 Percha.webp" alt="Percha"/>
          <Link to="/Percha">{I18n.t("Percha")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Preparaciones/000 Preparación tejido.webp" alt="Preparación"/>
          <Link to="/Preparaciones">{I18n.t("Preparaciones")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Rame/000 Rame.webp" alt="Rame"/>
          <Link to="/Rame">{I18n.t("Rame")}</Link>
        </span>
        <span className="link">
          <img className="background" src="" alt="Rolladora Empaquetadora"/>
          <Link to="/RolladoraEmpaquetadora">{I18n.t("Rolladora Empaquetadora")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Secaderos/000 Termofijadora.webp" alt="Secadero"/>
          <Link to="/Secaderos">{I18n.t("Secaderos")}</Link>
        </span>
        <span className="link" style={{ backgroundImage: ""}}>
          <img className="background" src="" alt="Estampación Digital"/>
          <Link to="/TrenDeLavado">{I18n.t("Tren de Lavado")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Tundosas/000 Super Tundosa.webp" alt="Tundosa"/>
          <Link to="/Tundosa">{I18n.t("Tundosa")}</Link>
        </span>
        <span className="link">
          <img className="background" src="/imagenes/productos/Vaporizadores/000 Vaporizador.webp" alt="Vaporizador"/>
          <Link to="/Vaporizador">{I18n.t("Vaporizador")}</Link>
        </span>
      </div>
    </main>
  );
}
ProductosController.propTypes = {
};

const mapStateToProps = state => ({
  i18n: state.i18n
});

export default connect(mapStateToProps)(ProductosController);
