import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const TundosaMain = props => {

    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return

        mesh.scale.set(0.0005, 0.0005, 0.0005)
        mesh.position.set(0, 0, 1.35)
        camera.position.set(2.5, 2, 2.5)
        scene.position.set(0, -1, 0)
    }

    return (
        <main className="producto__main tundosa__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.Tundosa.Tundosa")}</h1>
                    <p>{I18n.t("_Productos.Tundosa.Corte de alto rendimiento")}</p>
                    <p>
                        {I18n.t("_Productos.Tundosa.Velocidad de producción de hasta")} <b>22 m/min</b>
                    </p>
                </div>
                <TresDe 
                    url="/imagenes/productos/Tundosas/tundosa.stl" 
                    // url="/imagenes/productos/Tundosas/tundosa high.stl" 
                    modifyGeometry={modifyGeometry}
                />
            </div>
            
            <ContactUs />

            <div className="modelo__container">
                <Img height="100%" src={[ "" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.Tundosa.Tundosa")}</h1>
                <p>
                    {I18n.t("_Productos.Tundosa.Grupo de corte de Alto Rendimiento, único en el mercado por su solidez y precisión Movimiento axial del cilindro Tundidor Elevación neumática del cilindro tundidor para el afilado frontal de la hoja plana Altura de tundido motorizada con indicación digital Detector para el paso automático de la costura Indicación de todos los parámetros sobre el panel de trabajo")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Tundosas/000 Super Tundosa.webp", "/imagenes/productos/Tundosas/000 Super Tundosa.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Tundosas/001 Super Tundosa.webp", "/imagenes/productos/Tundosas/001 Super Tundosa.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Tundosas/002 Super Tundosa.webp", "/imagenes/productos/Tundosas/002 Super Tundosa.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Tundosas/003 Tundosa.webp", "/imagenes/productos/Tundosas/003 Tundosa.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Tundosas/004 Control.webp", "/imagenes/productos/Tundosas/004 Control.jpg" ]} />
                    </div>

                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.Tundosa.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.Tundosa.Ancho del grupo de corte")}</td>
                            <td>{I18n.t("_Productos.Tundosa.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tundosa.Ancho máximo del tejido")}</td>
                            <td>{I18n.t("_Productos.Tundosa.3200 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tundosa.Diámetro del cilindro tundidor")}</td>
                            <td>{I18n.t("_Productos.Tundosa.205 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tundosa.Velocidad del cilindro tundidor")}</td>
                            <td>{I18n.t("_Productos.Tundosa.500 a 1000 rpm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tundosa.Velocidad de producción")}</td>
                            <td>{I18n.t("_Productos.Tundosa.3 - 25 m/min")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Tundosa.Potencia instalada (incluida aspiración)")}</td>
                            <td>{I18n.t("_Productos.Tundosa.32 kW")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(TundosaMain)