import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";
import { connect } from "react-redux";

const RameMain = props => {
    
    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return

        mesh.scale.set(0.003, 0.003, 0.003)
        mesh.rotation.set(0,Math.PI / 2,0)
        mesh.position.set(0, .25, -1)
        scene.position.set(0, -1, 0)
        camera.position.set(3, 1.75, 0)

    }

    return (
        <main className="producto__main rame__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.RAME.RAME")}</h1>
                    <p>{I18n.t("_Productos.RAME.Tratamiento de tensado en telas a alta velocidad")}</p>
                    <p>
                        {I18n.t("_Productos.RAME.Producción de hasta")} <b>100 m/min</b>
                    </p>
                </div>
                <TresDe 
                    // url="/imagenes/productos/Rame/rame.stl" 
                    url="/imagenes/productos/Rame/rame low.stl" 
                    modifyGeometry={modifyGeometry}
                />
            </div>
            
            <ContactUs />

            <div className="modelo__container">
                <Img height="auto" width="100%" src={[ "/imagenes/productos/Rame/Rame Esquema.jpg" ]} />
            </div>
            <div className="descripcion__container">
                <h1>{I18n.t("_Productos.RAME.RAME")}</h1>
                <p >
                    {I18n.t("_Productos.RAME.Máquina dotada con cadenas para la preparación y el acabado de tejidos Su diseño permite, sólo abriendo las puertas laterales, la total accesibilidad a todo el circuito de ventilación La longitud del mismo dependerá de la necesidad del cliente")}
                </p>
                <p >
                    {I18n.t("_Productos.RAME.La distribución del aire en el interior de la máquina se realiza de modo que exista uniformidad de temperatura en el interior del mismo A la salida, el tejido puede ser enrollado en carros bota o doblado en carros")}
                </p>
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/000 Rame.webp", "/imagenes/productos/Rame/000 Rame.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/001 Panel control.webp", "/imagenes/productos/Rame/001 Panel control.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/002 Introduccion.webp", "/imagenes/productos/Rame/002 Introduccion.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/004 Introduccion Leimer.webp", "/imagenes/productos/Rame/004 Introduccion Leimer.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/005 Rame.webp", "/imagenes/productos/Rame/005 Rame.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/006 Rame.webp", "/imagenes/productos/Rame/006 Rame.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/007 Rame.webp", "/imagenes/productos/Rame/007 Rame.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/008 Rame.webp", "/imagenes/productos/Rame/008 Rame.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/009 Rame.webp", "/imagenes/productos/Rame/009 Rame.jpg" ]} />
                    </div>
                    <div className="element">
                        <Img src={[ "/imagenes/productos/Rame/010 Rame.webp", "/imagenes/productos/Rame/010 Rame.jpg" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.RAME.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.RAME.Ancho")}</td>
                            <td>{I18n.t("_Productos.RAME.1100")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RAME.Ancho de tabla")}</td>
                            <td>{I18n.t("_Productos.RAME.1600 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RAME.Ancho útil de trabajo")}</td>
                            <td>{I18n.t("_Productos.RAME.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RAME.Tensión de Conexión")}</td>
                            <td>{I18n.t("_Productos.RAME.380 v")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RAME.Frecuencia")}</td>
                            <td>{I18n.t("_Productos.RAME.50 - 60 Hz")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.RAME.Potencia Instalada")}</td>
                            <td>{I18n.t("_Productos.RAME.80 kW")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(RameMain)
