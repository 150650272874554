import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ButtonLink = props => {
  const { name, cambiarEstado, hideShow, noLink, ...res } = props;

  const onClick = ev => {
    if(noLink) ev.preventDefault()

    if (hideShow !== "") cambiarEstado();

    if (document.querySelector(`.${name}__container`)) 
      window.scrollTo(0, document.querySelector(`.${name}__container`).offsetTop);
      
  };

  return (
    <div className="link unselectable">
      {!noLink ? 
        <Link to={"/" + name} onClick={onClick} {...res}/>
        :
        res.children
      }
    </div>
  );
}
ButtonLink.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
  hideShow: PropTypes.any,
  noLink: PropTypes.any,
  cambiarEstado: PropTypes.func,
}

export default ButtonLink