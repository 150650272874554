import React from "react"
import { connect } from "react-redux"

const VideoMain = props => {
    return (
        <div className="video__main">
            <video autoPlay loop muted >
                <source src="/videos/tacome-maquinaria.mov" />
            </video>
        </div>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(VideoMain)