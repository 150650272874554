import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

import Utils from '../../utils';
import translations from '../../i18n/translations';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	// Reducers
	rootReducer,
	// Middlewares
	composeEnhancers(
		applyMiddleware(
			thunk
		)
	)
);

/**
 * I18n
 */
syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(Utils.getCookie("language") || "es"));


export default store