import React from "react";
// import PropTypes from "prop-types";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n"

/**
 * Nosotros Componente
 */

class Nosotros extends React.Component {
  constructor() {
    super();
    window.scrollTo(0, 0);

    this.state = {
      isDragging: false,
      x: 20,
      y: 50,
      currentPosition: 0,
      animation1: 0,
      scaleText: 0,
      posTop: 0
    };
  }
  componentDidMount() {
    // const history = this.context.router.history;
    // if (history.location.pathname == "/nosotros") {
    //   setTimeout(() => {
    //     window.scrollTo(
    //       0,
    //       document.querySelector(".nosotros__container").offsetTop - 95
    //     );
    //   }, 500);
    // }
  }

  render() {
    return (
      <section className="nosotros__container">
        
        <div className="topContainer">
          {/* <h3 className="_titulo">{I18n.t("AboutUs.titulo")}</h3> */}
          <h3 className="_titulo">Tacome</h3>
          <h4 className="_subtitulo">{I18n.t("AboutUs.texto")}</h4>
        </div>
        
        <div className="trayectoriaContainer">
          <div className="trayectoria">
            {/* <h3 className="_titulo">{I18n.t("Trayectoria.titulo")}</h3> */}
            <p>{I18n.t("Trayectoria.texto")}</p>
          </div>
        </div>
        
        <div className="slogan__container" style={{ backgroundImage: "url('/imagenes/homepage/handshake.jpg')"}}>
          <h4 className="cursiva">“{I18n.t("Slogan")}”</h4>
        </div>

      </section>
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Nosotros);
