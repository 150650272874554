import React from "react"
import { I18n } from "react-redux-i18n";
import { Img } from "react-image";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";

import ContactUs from "../componentes/contact_us"
import TresDe from "../componentes/tresDe";

const PreparacionesMain = props => {

    const modifyGeometry = (geometry, mesh, camera, scene) => {
        if(!geometry) return

        mesh.scale.set(0.0005, 0.0005, 0.0005)
        mesh.rotation.set(-Math.PI / 2,0,0)
        mesh.position.set(2, 0, -1)
        // scene.position.set(0, -1, 0)
        camera.position.set(3, 3, 3)
    }

    return (
        <main className="producto__main preparacion__main">
            <div className="informacion__container">
                <div className="text">
                    <h1>{I18n.t("_Productos.Preparaciones.Preparaciones")}</h1>
                    {/* <p>Tratamiento de tensado en telas a alta velocidad</p>
                    <p>
                        Producciónes de hasta <b>22 m/min</b>
                    </p> */}
                </div>
                <TresDe 
                    // url="/imagenes/productos/Preparaciones/preparacion.stl" 
                    url="/imagenes/productos/Preparaciones/preparacion.obj" 
                    materialUrl="/imagenes/productos/Preparaciones/preparacion.mtl" 
                    modifyGeometry={modifyGeometry}
                />
            </div>
            
            <ContactUs />

            <div className="modelo__container">
                <Img width="80%" height="auto" src={[ "/imagenes/productos/Preparaciones/esquema.jpg" ]} />
            </div>
            <div className="descripcion__container">
                {/* <h1></h1> */}
                {/* <p>
                </p> */}
            </div>
            <div className="carousel__container">
                <Carousel
                    interval={6000}
                    transitionTime={600}
                    showThumbs={false}
                    infiniteLoop
                    autoPlay
                    stopOnHover={false}
                    showStatus={false}
                    showIndicators={false}
                    className="carousel"
                >
                    <div className="element">
                        <Img height="100%" src={[ "/imagenes/productos/Preparaciones/000 Preparación tejido.webp", "/imagenes/productos/Preparaciones/000 Preparación tejido.jpg" ]} />
                    </div>
                </Carousel>
            </div>
            <div className="fichaTecnica__container">
                <h2>{I18n.t("_Productos.Preparaciones.Ficha técnica")}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>{I18n.t("_Productos.Preparaciones.Ancho")}</td>
                            <td>{I18n.t("_Productos.Preparaciones.1100")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Preparaciones.Ancho de tabla")}</td>
                            <td>{I18n.t("_Productos.Preparaciones.1600 mm")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Preparaciones.Ancho útil de trabajo")}</td>
                            <td>{I18n.t("_Productos.Preparaciones.Hasta 3400 mm de ancho útil")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Preparaciones.Tensión de Conexión")}</td>
                            <td>{I18n.t("_Productos.Preparaciones.380 v")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Preparaciones.Frecuencia")}</td>
                            <td>{I18n.t("_Productos.Preparaciones.50 - 60 Hz")}</td>
                        </tr>
                        <tr>
                            <td>{I18n.t("_Productos.Preparaciones.Potencia Instalada")}</td>
                            <td>{I18n.t("_Productos.Preparaciones.80 kW")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}

const mapStateToProps = state => ({
    i18n: state.i18n
})

export default connect(mapStateToProps)(PreparacionesMain)