import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";

import ButtonLink from "./Resources/buttonLink";
import ButtonGrid from "./Resources/buttonGrid";

let ticking = false;

const HeaderPhone = props => {
  const navBarRef = useRef();
  let fixed = props.fixed

  const handleClick = useCallback(ev => {
    if(navBarRef.current.contains(ev.target) === false && fixed === true)
      props.setFixed(false)
  }, [ fixed, props ])

  const cambiarEstado = () => {
    props.setFixed(!fixed);
  };

  useEffect(() => { 
    

    window.addEventListener("click", handleClick)

    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [ fixed, handleClick ])

  let hideShow = fixed ? "fixed" : "";

  return (
      <header>

        <ButtonGrid hideShow={hideShow} cambiarEstado={cambiarEstado} />

        <section className={`nav_holder ${hideShow}`} ref={navBarRef}>

          <div className="links">

            <div className="link">
              <Link to={"/"}><img className="svgTacomeName" src="/imagenes/svg/tacomeLogos/tacomeNameIcon.svg" alt="tacome navigation icon"/></Link>
            </div>

            <ButtonLink name={"inicio"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Inicio")}
            </ButtonLink>

            <ButtonLink name={"nosotros"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Nosotros")}
            </ButtonLink>

            <ButtonLink name={"productos"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Productos")}
            </ButtonLink>

            <ButtonLink name={"contacto"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Contacto")}
            </ButtonLink>
          </div>
        </section>

      </header>
  );
}
HeaderPhone.propTypes = {
  fixed: PropTypes.bool,
  setFixed: PropTypes.func
}

// eslint-disable-next-line no-unused-vars
let lastScrollY;
const HeaderNormal = props => {
  const productosListRef = useRef();
  const productosLinkRef = useRef();
  const navBarRef = useRef();

  let fixed = props.fixed

  // Funcion para comprobar el movimiento de la pantalla en Y
  const handleScroll = useCallback(() => {
    lastScrollY = window.scrollY;
    let width = window.innerWidth;

    if (!ticking && width > 780) {

      window.requestAnimationFrame(() => {
        // props.setFixed(lastScrollY <= 200 ? false : true);
        ticking = false;
      });

      ticking = true;
    }
  }, []);
  // Funcion para comprobar el movimiento de la pantalla en X
  const handleResize = useCallback(() => {
    let width = window.innerWidth;
    if (width <= 780) props.setFixed(false);
  }, [ props ]);
  // Funcion para cambiar el estado del estado 'hideShow',
  // des esta forma veremos o no el menu
  const cambiarEstado = useCallback(() => {
    props.setFixed(!fixed);
  }, [ props, fixed ]);

  useEffect(() => {  
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    // window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      // window.removeEventListener("click", handleClick);
    }
  }, [ handleScroll, handleResize ])

  let hideShow = fixed ? "fixed" : "";

  return (
      <header>

        <ButtonGrid hideShow={hideShow} cambiarEstado={cambiarEstado} />

        <section className={`nav_holder ${hideShow}`} ref={navBarRef}>

          <div className="links">

            <div className="link">
              <Link to={"/"}><img className="svgTacomeName" src="/imagenes/svg/tacomeLogos/tacomeNameIcon.svg" alt="tacome name icon" /></Link>
            </div>

            <ButtonLink name={"inicio"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Inicio")}
            </ButtonLink>

            <ButtonLink name={"nosotros"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Nosotros")}
            </ButtonLink>

            <div 
              className="link unselectable" 
              ref={productosLinkRef}
              onMouseLeave={ev => productosListRef.current.classList.remove("open")}
            >
              <Link 
                to="/productos"
                onClick={ev => productosListRef.current.classList.remove("open")}
                onMouseOver={ev => productosListRef.current.classList.add("open")}
              >{I18n.t("Productos")}</Link>
              <div className="productosList" ref={productosListRef}>
                <Link className="product" to="Empaquetadora">{I18n.t("Empaquetadora")}</Link>
                <Link className="product" to="EstampacionDigital">{I18n.t("Estampación Digital")}</Link>
                <Link className="product" to="Percha">{I18n.t("Percha")}</Link>
                <Link className="product" to="Preparaciones">{I18n.t("Preparaciones")}</Link>
                <Link className="product" to="Rame">{I18n.t("Rame")}</Link>
                <Link className="product" to="Secaderos">{I18n.t("Secaderos")}</Link>
                <Link className="product" to="TrenDeLavado">{I18n.t("Tren de Lavado")}</Link>
                <Link className="product" to="Tundosa">{I18n.t("Tundosa")}</Link>
                <Link className="product" to="Vaporizador">{I18n.t("Vaporizador")}</Link>
                <Link className="product" to="RolladoraEmpaquetadora">{I18n.t("Rolladora Empaquetadora")}</Link>
              </div>
            </div>

            <ButtonLink name={"contacto"} hideShow={hideShow} cambiarEstado={cambiarEstado} >
              {I18n.t("Contacto")}
            </ButtonLink>
          </div>
        </section>

      </header>
  );
}
HeaderNormal.propTypes = {
  fixed: PropTypes.bool,
  setFixed: PropTypes.func
}

// let timeOutResize
const Header = props => {
  const [ innerWidth, setInnerWidth ] = useState(window.innerWidth) 

  const handleResize = useCallback(() => {
    // clearTimeout(timeOutResize)
    // timeOutResize = setTimeout(() => { setInnerWidth(window.innerWidth) }, 500)
    setInnerWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [ handleResize ])

  if(innerWidth <= 780) return <HeaderPhone {...props} />

  return <HeaderNormal {...props} />
}

const mapStateToProps = state => ({ 
  i18n: state.i18n,
  fixed: state.header.fixed
});
const mapDispatchToProps = dispatch => ({
  setFixed: fixed => dispatch({ type: "heade/set_fixed", fixed })
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
